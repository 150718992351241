<template>
	<el-dialog :visible="dialogShow" :before-close="()=>{$emit('close')}" :modal-append-to-body='false'>
		<div class="orderDetailWrap">
			<div style="padding: 0 10px;">
				<div style="display: flex;align-items: center;padding: 10px;">
					<span style="font-size: 20px;font-weight: bold;">订单详情</span>
				</div>
				<div
					style="padding: 10px;display: flex;justify-content: space-between;border-bottom: 1px solid #E8EBF1;">
					<div style="padding: 10px 0;display: flex;justify-content: flex-start;">
						<div style="font-size: 13px;margin-right: 40px"><span style="color: #898E98;">订单号:
							</span><span>{{ data.orderNo }}</span></div>
						<div style="font-size: 13px;;margin-right: 40px"><span style="color: #898E98;">商品:
							</span><span>{{ data.goodsName }} </span></div>
						<div style="font-size: 13px;;margin-right: 40px"><span style="color: #898E98;">规格:
							</span><span>{{ data.specName }} </span></div>
					</div>
					<!-- <div
            style="padding: 5px 40px;border-radius: 5px;color: #fff;background: #51CBCD;font-weight: bold;font-size: 13px;display: flex;align-items: center;">
            退 款</div> -->
				</div>
				<div style="width: 100%;overflow:auto;border-bottom: 1px solid #E8EBF1;padding: 10px;">
					<div style="font-weight: bold;font-size: 17px;padding: 10px;">评价信息</div>
					<div>

						<div class="commentList">
							<div class="commentItem">
								<div class="commentTop">
									<div class="leftInfo">
										<img :src="data.avatarUrl ? data.avatarUrl : `${baseImgUrl}mktpic/dafault.png`"
											class="avatar" />
										<div class="userInfo">
											<div class="name">{{data.nickeName ? data.nickeName : '匿名买家'}}</div>
											<div class="time">{{data.createTime}}</div>
										</div>
									</div>
									<div class="rateBox">
										<el-rate v-model="data.rate" disabled disabled-void-color="rgb(173 182 197)"
											show-score text-color="#ff9900" style="display: inline-block;">
										</el-rate>
										<span class="rateNum"></span>
									</div>
								</div>

								<div style="margin-left: 45px">
									<div class="commentText">
										{{ data.content}}
									</div>
									<div class="photoList" v-if="data.imgList && data.imgList.length">
										<div class="photoBox" v-for="i in data.imgList" :key="i">
											<el-image style="width: 100%; height: 100%;" :src="baseImgUrl + i"
												:preview-src-list="[baseImgUrl + i]">
											</el-image>
										</div>
									</div>
									<div class="replyBox" v-if="sjData">
										<div>
											<span>商家回复：</span><span>{{sjData.content}}</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div style="margin-top: 10px; padding-left: 10px;margin-left: 45px" v-if="zjData">
						<div>
							<span class="zpTip">{{zjData.createTime?getFormatTime(zjData.createTime, false):''}}追加评价</span><span
								class="zjTime">{{zjData.createTime}}</span>
						</div>
						<div class="zjText">
							{{zjData.content}}
						</div>

						<div class="photoList" v-if="zjData.imgs">
							<div class="photoBox" v-for="i in zjData.imgs.split(',')" :key="i">
								<el-image style="width: 100%; height: 100%;" :src="baseImgUrl + i"
									:preview-src-list="[baseImgUrl + i]">
								</el-image>
							</div>
						</div>

					</div>
				</div>
				<!-- <div style="width: 100%;overflow:auto;border-bottom: 1px solid #E8EBF1;padding: 10px;">
          <div style="font-weight: bold;font-size: 17px;padding: 10px;" v-if="data.evaluateInfos && data.evaluateInfos.length">
            <div style="font-weight: bold;font-size: 17px;padding: 10px;">商家回复</div>
            <div v-for="reItem in data.evaluateInfos" :key="reItem" class="reItem">
              {{ reItem.content}}
              <span>{{reItem.createTime}}</span>
            </div>
          </div>
        </div> -->
				<!--        <div>-->
				<!--          <div style="font-weight: bold;font-size: 17px;padding: 10px;">评论信息</div>-->
				<!--          <template v-if="plData && plData.length > 0">-->
				<!--            <div class="companyBox" style="margin-top: 20px;" v-for="item in plData" :key="item.id">-->
				<!--              <div >-->
				<!--                <el-image-->
				<!--                    style="width: 50px; height: 50px;border-radius:50%"-->
				<!--                    :src="item.avatarUrl"-->
				<!--                    fit="fit"></el-image>-->
				<!--                <div >-->
				<!--                  {{item.nickeName}}-->
				<!--                </div>-->
				<!--              </div>-->

				<!--            </div>-->
				<!--          </template>-->
				<!--          <template v-else><el-empty description="无数据"></el-empty></template>-->
				<!--        </div>-->

			</div>
			<!--      <template v-if="orderData.companyItemList && orderData.companyItemList.length">-->
			<!--        <div class="companyBox" style="margin-top: 20px;" v-for="companyItem in orderData.companyItemList" :key="companyItem.companyId">-->
			<!--          &lt;!&ndash; <div class="companyName"><img src="" />公司名称：{{ companyItem.companyName }}</div> &ndash;&gt;-->
			<!--          &lt;!&ndash; <div class="goodsBox" v-for="goodItem in companyItem.skuGoodVoList" :key="goodItem.goodsId"> &ndash;&gt;-->
			<!--          <el-table :data="companyItem.skuGoodVoList" style="width: 100%">-->
			<!--            <el-table-column prop="goodsName" label="商品名称" align="center">-->
			<!--            </el-table-column>-->
			<!--            <el-table-column label="商品图片" align="center">-->
			<!--              <template slot-scope="scope">-->
			<!--                <img :src="baseImgUrl + scope.row.img" class="goodsPhoto" />-->
			<!--              </template>-->
			<!--            </el-table-column>-->
			<!--            <el-table-column prop="specName" label="商品规格" align="center">-->
			<!--            </el-table-column>-->
			<!--            <el-table-column prop="specName" label="单价*数量" align="center">-->
			<!--              <template slot-scope="scope">-->
			<!--                <template>-->
			<!--                  {{ scope.row.price | formatAmount}}*{{scope.row.num}}-->
			<!--                </template>-->
			<!--              </template>-->
			<!--            </el-table-column>-->
			<!--            <el-table-column prop="subAmount" label="优惠金额" align="center">-->
			<!--              <template slot-scope="scope">-->
			<!--                {{scope.row.subAmount| formatAmount}}-->
			<!--              </template>-->
			<!--            </el-table-column>-->
			<!--            <el-table-column prop="subAmount" label="商品状态" align="center">-->
			<!--              <template slot-scope="scope">-->
			<!--                {{getGoodState(scope.row.state)}}-->
			<!--              </template>-->
			<!--            </el-table-column>-->
			<!--            <el-table-column prop="allPrice" label="实付金额" align="center">-->
			<!--              <template slot-scope="scope">-->
			<!--                 {{scope.row.allPrice| formatAmount}}-->
			<!--              </template>-->
			<!--            </el-table-column>-->
			<!--            <el-table-column prop="adminAmount" label="预计收入" align="center">-->
			<!--              <template slot-scope="scope">-->
			<!--                {{scope.row.adminAmount| formatAmount}}-->
			<!--              </template>-->
			<!--            </el-table-column>-->
			<!--          </el-table>-->
			<!--          &lt;!&ndash; </div> &ndash;&gt;-->
			<!--        </div>-->
			<!--      </template>-->
			<!--      <div class="orderPayBox">-->
			<!--        <div>-->
			<!--          <span style="color: #898E98;">应付金额：</span><span>￥{{ orderData.orderPrice | formatAmount}}</span>-->
			<!--        </div>-->
			<!--        <div>-->
			<!--          <span style="color: #898E98;">实付金额：</span><span>￥{{ orderData.payPrice | formatAmount}}</span>-->
			<!--        </div>-->
			<!--        &lt;!&ndash; <div>-->
			<!--          <span style="color: #898E98;">结算总计：</span><span>￥{{ orderData.orderPrice | formatAmount}}</span>-->
			<!--        </div> &ndash;&gt;-->
			<!--      </div>-->
		</div>
	</el-dialog>
</template>
<script>
	import {
		baseImgUrl
	} from '@/utils/constant'
	import {
		fetchOrderEvaluateDetail
	} from '@/api/order'
	import {
		formatAmount,
		getFormatTime1
	} from '@/utils/utils'
	export default {
		name: "evaluateDetail",
		props: {
			'dialogShow': {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				id: null,
				data: {},
				zjData: null,
				sjData: null,
				plData: [],
				baseImgUrl: baseImgUrl,
				orderStateText: ''
			}
		},
		components: {},
		mounted() {
			this.init(this.id)
		},
		filters: {
			formatAmount(amount) {
				return formatAmount(amount)
			},

		},
		methods: {
			getFormatTime(val) {
				return getFormatTime1(val);
			},
			init(id) {
				this.zjData = null
				this.sjData = null
				this.id = id
				let v = this
				if (this.id) {
					let params = {
						id: this.id
					}
					fetchOrderEvaluateDetail(params).then(res => {
						if (res.data) {
							this.data = res.data

							if (res.data.imgs) {
								this.data.imgList = res.data.imgs.split(',')
							} else {
								this.data.imgList = []
							}

							if (this.data.evaluateInfos.length) {
								this.data.evaluateInfos.forEach(item => {
									if (item.type == 1) {
										this.zjData = item
									} else if (item.type == 2) {
										this.sjData = item
									} else {
										this.plData.push(item)
									}
								})
							}
						}
					})
				}
			},
			getGoodState(state) {
				if (state && state === 7) {
					return '退款申请中'
				} else if (state && state === 6) {
					return '售后完成'
				}
				return '正常'
			},
			goWuliu(num) {
				window.open(`https://www.kuaidi100.com/?nu=${num}`)
			},
			getOrderState(orderState, refundState) {
				let str = ''
				let stateArr = ['已过期', '待付款', '待发货', '待收货', '已完成', '交易取消', '售后完成', '退款申请中']
				let refundArr = [{
						num: 1,
						text: '未申请退款'
					},
					{
						num: 2,
						text: '退款申请中',
					},
					{
						num: 3,
						text: '退款申请成功',
					},
					{
						num: 4,
						text: '取消退款'
					},
					{
						num: 8,
						text: '待退货',
					},
					{
						num: 9,
						text: '退货中',
					},
				]
				if (refundState === 1) {
					str = stateArr[orderState]
				} else {
					str = refundArr.filter(item => item.num === refundState)[0].text
				}
				return str

			}
		}
	}
</script>
<style>
	.goodsPhoto {
		width: 60px;
		height: 60px;
	}

	.companyName {
		border: 1px solid #ccc;
		height: 40px;
		line-height: 40px;
		background: #fff;
		margin-top: 10px;
	}

	.red {
		color: #f00;
	}

	.orderDetailWrap {
		background-color: #fff;
		padding: 10px;
	}

	.orderPayBox {
		text-align: right;
		font-size: 13px;
		margin: 20px;
	}

	.orderPayBox>div {
		padding: 5px 0;
	}

	.reItem {
		font-size: 12px;
		font-weight: normal;
	}

	.reItem span {
		display: block;
		text-align: right;
	}

	.el-rate__text {
		color: #A1A8B1 !important;
	}

	.commentList {
		padding: 0 10px;
	}

	.commentItem {
		margin: 10px 0;
	}

	.commentItem .commentTop {
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	.leftInfo {
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	.commentTop .avatar {
		width: 35px;
		height: 35px;
		border-radius: 50%;
		margin-right: 10px;
		border: 1px solid #eee;
	}

	.userInfo .name {
		font-size: 15px;
		font-family: Source Han Sans CN;
		font-weight: 500;
		color: #333333;
	}

	.userInfo .time {
		font-size: 12px;
		font-family: Source Han Sans CN;
		font-weight: 400;
		color: #A1A8B1;
		padding-top: 3px;
	}

	.commentText {
		font-size: 12px;
		font-family: Source Han Sans CN;
		font-weight: 400;
		color: #333333;
		line-height: 20px;
		padding: 10px 0;
	}

	.rateBox {
		font-size: 14px;
		color: rgb(255, 153, 0);
		line-height: 25px;
		display: flex;
		align-items: center;
		margin: 5px 20px;

	}

	.photoList {
		display: flex;
		flex-wrap: wrap;
		margin: 10px 0;
	}

	.photoBox {
		width: 75px;
		height: 75px;
		border-radius: 5px;
		margin-right: 5px;
		margin-bottom: 5px;
		overflow: hidden;
		border: 1px solid #ccc;

	}

	.replyBox {
		background: #eee;
		border-radius: 5px;
		padding: 10px;
		font-size: 12px;
		font-family: Source Han Sans CN;
		font-weight: 400;
		color: #333333;
		line-height: 20px;
		margin: 10px 0;
	}

	.zpTip {
		font-size: 15px;
		font-family: Source Han Sans CN;
		font-weight: 500;
		color: #1855DE;
		line-height: 20px;
	}

	.zjTime {
		font-size: 12px;
		font-family: Source Han Sans CN;
		font-weight: 400;
		color: #A1A8B1;
		margin-left: 10px
	}

	.zjText {
		font-size: 12px;
		font-family: Source Han Sans CN;
		font-weight: 400;
		color: #333333;
		line-height: 20px;
		padding: 10px 0;
	}
</style>
