<template>
  <div class="view pa24">
    <el-row :gutter="24">
      <el-col :xl="24" :lg="16" class="d-flex justify-content-between w100p">
        <div class="d-flex align-items-center">
          <el-input
              v-model="searchKey"
              class="mb10"
              style="width: 240px;margin-right: 10px"
              placeholder="请输入买家昵称/订单编号/商品id"
          />
          <el-select v-model="answerState" placeholder="请选择回复状态"  class="mb10">
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
          <el-button class="ml10 mb10" type="primary" @click="getEvaluate">搜索</el-button>
          <el-button class="ml10 mb10" type="primary" @click="reset">重置</el-button>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <div class="align-items-center">
        <el-button type="primary"
                   class="mb10"
                   :disabled="answerForm.ids.length <= 0"
                   @click="moreAnswer">批量回复</el-button>
      </div>
    </el-row>
    <commonTable :tableData="tableData"
                 @handleSizeChange="handleSizeChange"
                 @handleCurrentChange="handleCurrentChange"
                 :currentPage="currentPage"
                 :loading="loading"
                 :total="total"
                 @handleSelectionChange="handleSelectionChange"
    >
      <template v-slot:table>
         <el-table-column  :selectable="selectable" type="selection" width="55" />
        <el-table-column prop="rate" width="164px" align="center" label="评分" >
          <template slot-scope="scope">
            <el-rate
                v-model="scope.row.rate"
                disabled
                disabled-void-color="rgb(173 182 197)"
                show-score
                text-color="#ff9900"
            >
            </el-rate>
          </template>
        </el-table-column>
        <el-table-column prop="content" width="200px" align="center" label="评价内容" >
          <template slot-scope="scope">
            <el-row>
             {{scope.row.content}}
            </el-row>
            <el-row>
              <span class="orderInfo">{{scope.row.createTime}}</span>
            </el-row>
          </template>
        </el-table-column>
        <el-table-column prop=""  align="center" label="宝贝信息" >
          <template slot-scope="scope">
            <el-row>
                <el-button type="text">{{scope.row.goodsName}}</el-button>
            </el-row>
            <el-row>
                <span class="orderInfo">订单号：{{scope.row.orderNo}}</span>
            </el-row>
          </template>
        </el-table-column>
        <el-table-column prop="personalWx" align="center" label="买家信息" >
          <template slot-scope="scope">
            <div class="userBox">
              <el-image
                  style="width: 40px; height: 40px;border-radius: 50%; margin-right: 10px;"
                  :src="scope.row.avatarUrl ? scope.row.avatarUrl :`${baseImgUrl}mktpic/dafault.png`"
                  fit="fit"></el-image>
                  {{scope.row.nickeName ? scope.row.nickeName : '微信用户'}}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="120px">
          <template slot-scope="scope">
            <el-button type="text" @click="answer(scope.row)" v-if="!scope.row.isAnswer">回复</el-button>
            <el-button type="text" @click="getDetail(scope.row)" >详情</el-button>
          </template>
        </el-table-column>
      </template>
    </commonTable>

<!--    &lt;!&ndash;回复弹窗&ndash;&gt;-->
    <el-dialog
        title="回复"
        center
        :visible.sync="dialogVisible"
        :modal-append-to-body="false"
        width="30%"
        :close-on-click-modal="false"
    >
      <el-form
          label-width="auto"
          :rules="answerRules"
          ref="ruleForm"
          :model="answerForm"
      >
        <el-form-item label="回复内容" prop="content">
          <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入内容"
              v-model="answerForm.content">
          </el-input>

        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="closeChangeDialog">取 消</el-button>
        <el-button type="primary" @click="toAnswer"
        >确 定</el-button
        >
      </span>

    </el-dialog>
    <evaluate-detail-component ref="detail" @close="detailShow=false" :dialog-show="detailShow"></evaluate-detail-component>

  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import { fetchOrderEvaluateList,fetchOrderEvaluateAnswer} from "@/api/order.js";
import EvaluateDetailComponent from "@/components/Order/EvaluateDetailComponent";
import {
  baseImgUrl
} from '@/utils/constant'

export default {
  name: "evaluateManagement",
  components: {
    commonTable,
    EvaluateDetailComponent
  },
  data() {
    return {
      hasPermi: true,
      tableData: [],
      dialogVisible: false,
      detailShow: false,
      total: 0, //总条数
      currentPage: 1, //当前页数
      queryDate: "",
      pageNum: 1,
      pageSize: 10,
      loading: false, //加载中
      searchKey:'', //关键字
      answerForm:{
        ids:[],
        content:null
      },
      answerRules:{
        content:[
          { required: true, message: '请填写回复内容', trigger: 'blur' }
        ]
      },
      answerState:null,
      options:[
        {value:true,label:'已回复'},
        {value:false,label:'未回复'},
      ],
      baseImgUrl: baseImgUrl,
    };
  },
  computed: {
  },
  watch:{
  },
  filters: {
  },
  mounted() {
    this.getEvaluate();
  },
  created() {
    if(JSON.parse(localStorage.getItem('isSourceAdmin'))) {
      this.hasPermi = false
    }
  },
  methods: {
    getDetail(data) {
      this.$refs.detail.id = data.id
      this.detailShow=true;
      this.$refs.detail.init(data.id)
    },
    //回复
    moreAnswer(row){
      this.answerForm.content = null
      this.dialogVisible = true
    },
    answer(row){
      this.answerForm.ids = []
      this.answerForm.content = null
      this.answerForm.ids.push(row.id)
      this.dialogVisible = true
      this.getEvaluate()
    },
    //获取评价列表
    getEvaluate() {
      let data = {
        pageNum:this.pageNum,
        pageSize:this.pageSize,
        keyWords:this.searchKey,
        isAnswer:this.answerState
      }
      fetchOrderEvaluateList(data)
          .then((res) => {
            this.loading = false;
            this.tableData = res.data.list;
            this.total = res.data.total

          })
          .catch((err) => {
            this.tableData = []
            this.loading = false;
            console.log(err);
          });
    },
    //当前页变更
    handleCurrentChange(num) {
      this.pageNum = num;
      this.loading = true;
      this.getEvaluate();
    },
    //当前页查询数据条数变更
    handleSizeChange(num) {
      this.pageSize = num;
      this.pageNum = 1;
      this.currentPage = 1;
      this.loading = true;
      this.getEvaluate();
    },
    closeChangeDialog() {
      this.dialogVisible = false
    },
    toAnswer(){
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          fetchOrderEvaluateAnswer(this.answerForm).then(response=>{
            if (response.code == 200){
              this.dialogVisible = false
              this.$message({
                type: "success",
                message: "操作成功",
              });
              this.answerForm.ids = []
              this.answerForm.content = null
              this.getEvaluate();
            }
          })
        } else {

        }
      });
      //
    },
    handleSelectionChange(val){
      this.answerForm.ids =  val.map(item => item.id);
    },
    selectable( row, index) {
      if( row.isAnswer) {
        return false;
      }else {
        return true;
      }
    },
    reset(){
      this.answerState = null
      this.searchKey = null
      this.getEvaluate()
    },
    detail(row){
      this.detailDialogVisible = true
    }

  },
};
</script>

<style scoped>
/deep/ input::-webkit-outer-spin-button,
/deep/ input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
/deep/ input[type="number"] {
  -moz-appearance: textfield !important;
}
.orderInfo{
  color:#9b9999;
}
.userBox{
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
